import React, { useCallback, useEffect, useState } from 'react'
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import * as queries from './graphql/queries';
import { SASpinner, SAUX360Theme, SAUXTheme } from "@saux/design-system-react";
import { AppContextProvider } from "./components/AppContext/AppContext";
import Header from "./components/Header/Header";
import AgencyContacts from "./components/AgencyContacts/AgencyContacts";
import ContactTypes from "./components/ContactTypes/ContactTypes";
import GeneralDetails from "./components/GeneralDetails/GeneralDetails";
import AgencyCodes from "./components/AgencyCodes/AgencyCodes";
import PrimaryAgencyCode from "./components/PrimaryAgencyCode/PrimaryAgencyCode";
import UserPrivileges from "./components/UserPrivileges/UserPrivileges";
import Login from "./components/Login/Login";
import Logout from "./components/Logout/Logout";
import AgencyContactDetails from "./components/AgencyContactDetails/AgencyContactDetails";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SearchAgencyContact from "./components/SearchAgencyContact/SearchAgencyContact";
import AgencyAdministration from "./components/AgencyAdministration/AgencyAdministration";
import CommunicationPreferences from './components/CommunicationPreferences/CommunicationPreferences';
import ContactListingReport from './components/ContactListingReport/ContactListingReport';
import { API, Amplify, Auth, Hub, graphqlOperation } from 'aws-amplify';
import env from './env_config';
import { decrypt, encrypt } from './utils/crypto';
import { v4 as uuidv4 } from 'uuid';
import TagManager from "react-gtm-module";
import ErrorPage from './components/ErrorPage/ErrorPage';
import { TerminateAgencyContacts } from './components/TerminateAgencyContacts/TerminateAgencyContacts';
import { isAgencyOpsInternalUser } from './utils';
import { CheckPersonActiveNucleusQuery, EnableDisableSelfServiceQuery } from './API';
import ModalAlert from './components/modal/ModalTerminateContactsAlert/ModalTerminateContactsAlert';
import handleGraphQLErrors from './utils/handleGraphQLErrors';
import { SpinnerContainer } from './components/common/commonComponents';

const myAppConfig = {
  'aws_appsync_graphqlEndpoint': env['graphql'].aws_appsync_graphqlEndpoint,
  'aws_appsync_region': env['graphql'].aws_appsync_region,
  'aws_appsync_authenticationType': env['graphql'].aws_appsync_authenticationType,
  'aws_project_region': env['authentication'].aws_project_region,
  'aws_cognito_region': env['authentication'].aws_cognito_region,
  'aws_user_pools_id': env['authentication'].aws_user_pools_id,
  'aws_user_pools_web_client_id': env['authentication'].aws_user_pools_web_client_id,
  'oauth': env['oauth'],
}
Amplify.configure(myAppConfig);
Auth.configure(myAppConfig);

type StyleProps = {
  theme?: SAUXTheme;
};

const Container = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin: 0 auto;
      max-width: 1200px;
    
      @media only screen and (max-width: 600px) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    `;
  }};
`;

type SAGlobalStyles = {
  theme?: SAUXTheme;
};

const fontOverride: SAUXTheme = {
  ...SAUX360Theme,
  font: {
    ...SAUX360Theme.font,
    primary: {
      ...SAUX360Theme.font.primary,
      family: 'Lato, sans-serif',
      url: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
    },
  },
};

const SelfServiceGlobalStyle = createGlobalStyle`
  ${(props: SAGlobalStyles) => {
    const { theme } = props;

    return `
      .noscroll {
        overflow: hidden;
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      html, body {
        background-color: ${theme?.color.background.background};
        font-family: 'Lato', sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
      }

      [data-whatintent='mouse'] *:focus {
        outline: none !important;
      }

      [data-whatintent='mouse'] *:focus + .saCheckboxIconWrapper::before {
        display: none !important;
      }

      [data-whatintent='mouse'] *:focus + .radio::after {
        display: none !important;
      }
    `;
  }};
`;

function App() {
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [isInvalidUser, setIsInvalidUser] = useState(false);
  const [signInErrorOccurred, setSignInErrorOccurred] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);
  const [isAgencyOpsUser, setIsAgencyOpsUser] = useState(false);
  const [disablePortal, setDisablePortal] = useState<{
    isDisable: boolean;
    message: string;
  } | null>(null);
  const [userStatus, setUserStatus] = useState<"Active" | "Inactive" | "None">("None");
  const [personType, setPersonType] = useState<"Person" | "Employee" | "None">("None");
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);

  // GTM ID - Google Tag Manger
  const gtmId = 'GTM-TNTJNHX';
  
  const knownRoutes = [
    "logout",
    "contact-listing",
    "agency-contacts",
    "terminate-agency-contacts",
    "contact-types",
    "general-details",
    "agency-codes",
    "primary-agency-code",
    "agency-contact-profile",
    "search-agency-contact",
    "agency-administration",
    "communication-preferences",
    "error"
  ];

  const isUnknownRoutes = () => {
    const link = window.location.href;
    const path = window.location.pathname;
    const route = link.split("/").pop() ?? "";
    return path === "/" ? false : !knownRoutes.includes(route);
  };

  function initializeGTM() {
    const userID = decrypt(localStorage.getItem('auth_userid'));

    TagManager.initialize({
      gtmId,
      dataLayer: {
        csrId: userID
      }
    });
  }

  function onClickClose(): void {
    window.location.href = env.saconnect;
  }

  const doSignIn = useCallback(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((user) => {
        console.log(user);
        const gainRefID = user.attributes ? user.attributes['custom:GainRefID'] : null;
        const userType = user.attributes ? user.attributes['custom:userType'] : null;
        const userID = user.attributes ? user.attributes['custom:userID'] : null;
        const personID = user?.signInUserSession?.idToken?.payload ? user?.signInUserSession?.idToken?.payload['custom:personID'] : null;
        const accessToken = user?.signInUserSession?.accessToken.jwtToken;
        const refreshToken = user?.signInUserSession?.refreshToken.token;

        console.log('auth_gainrefid', gainRefID);        
        console.log('auth_usertype', userType);
        console.log('auth_userid', userID);
        console.log('auth_personid', personID);
        console.log('auth_accesstoken', accessToken);
        console.log('auth_refreshtoken', refreshToken);

        let expDate = new Date();
        expDate.setHours(expDate.getHours() + 12);
        document.cookie = `selfservice_cognitodomain=${env.oauth.domain};expires=${expDate};domain=.stateauto.com`;
        document.cookie = `selfservice_clientid=${env.authentication.aws_user_pools_web_client_id};expires=${expDate};domain=.stateauto.com`;

        if (gainRefID !== null) {
          localStorage.setItem('auth_gainrefid', encrypt(gainRefID));
        } else {
          localStorage.removeItem('auth_gainrefid');
        }

        if (accessToken) {
          localStorage.setItem('auth_accesstoken', encrypt(accessToken));
        } else {
          localStorage.removeItem('auth_accesstoken');
        }

        if (personID) {
          localStorage.setItem('auth_personid', encrypt(personID));
        } else {
          localStorage.removeItem('auth_personid');
        }

        if (userType) {
          localStorage.setItem('auth_usertype', encrypt(userType));
          setPersonType("Person");
        } else if (userID) {
          localStorage.setItem('auth_usertype', encrypt('E'));
          setPersonType("Employee");
          setUserId(userID);
        } else {
          localStorage.removeItem('auth_usertype');
        }

        if (userID) {
          localStorage.setItem('auth_userid', encrypt(userID));
        } else {
          localStorage.removeItem('auth_userid');
        }

        if (refreshToken) {
          localStorage.setItem('auth_refreshtoken', encrypt(refreshToken));
          document.cookie = `selfservice_refreshtoken=${refreshToken};expires=${expDate};domain=.stateauto.com`;
        } else {
          localStorage.removeItem('auth_refreshtoken');
        }
        setSignedIn(true);
        initializeGTM();
      })
      .catch((err) => {
        console.error('caught error', err);
        if (err === 'not authenticated' || err === 'The user is not authenticated') {
          console.log('window.location.href', window.location.href);
          const performRedirect = new URL(window.location.href).search.length > 0 ? 'true' : 'false';
          localStorage.setItem('redirect', performRedirect);
          localStorage.setItem('redirectLocation', window.location.href);
          console.log('calling federatedSignIn');

          Auth.federatedSignIn(
            {
              customProvider: 'EmpowerID'
            }
          )
          .catch((_err) => {
            console.error('error signing in', _err);
            window.location.href = "error";
          });
        } else {
          window.location.href = "error";
          console.error(err);
        }
      });
  }, []);

  useEffect(() => {
    const loggingout: boolean = localStorage.getItem('loggingout') && localStorage.getItem('loggingout')  === 'true' ? true : false;
    const loggedout: boolean = localStorage.getItem('loggedout') && localStorage.getItem('loggedout') === 'true' ? true : false;
    if (signedIn) {
      console.log('signedIn');
      const redirectLocation = localStorage.getItem('redirectLocation');
      const redirect = localStorage.getItem('redirect');
      localStorage.removeItem('redirectLocation');
      localStorage.removeItem('redirect');
      console.log('redirectLocation', redirectLocation);
      console.log('redirect', redirect);
      if (redirect === 'true') {
        window.location.assign(redirectLocation as string);
      }
    } else if (!loggingout && !loggedout) {
      console.log('calling doSignIn');
      doSignIn();
    }
    
    if (loggedout) {
      localStorage.removeItem('loggedout');
    }
  }, [signedIn]);

  useEffect(() => {
    async function checkIfPortalDisabled() {
      const promiseAgencyCodes = API.graphql(
        graphqlOperation(queries.enableDisableSelfService),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
        }
      ) as Promise<{ data: EnableDisableSelfServiceQuery }>;

      promiseAgencyCodes
        .then((res) => {
            return res.data.EnableDisableSelfService;
        })
        .then((res) => {
          if (res?.statusCode === 200 && res.body) {
            const { disable_portal, message } = res.body;
            setDisablePortal({
              isDisable: disable_portal === "True" ? true : false,
              message: message ?? "",
            });
          } else {
            setDisablePortal({
              isDisable: false,
              message: "",
            });
          }
        })
        .catch((err) => {
          if (err?.message) {
            console.error(err.message, err);
          } 
          setDisablePortal({
            isDisable: false,
            message: "",
          });
        });
    }

    if (signedIn) {
      checkIfPortalDisabled();
    }
  }, [signedIn]);

  useEffect(() => {
    async function checkIfPersonIsActive() {
      setShowLoadingSpinner(true);
      const personID = decrypt(localStorage.getItem('auth_personid'));
      const promise = API.graphql(
        graphqlOperation(queries.checkPersonActiveNucleus, {
          person_id: personID
        }),
        {
          Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
        }
      ) as Promise<{ data: CheckPersonActiveNucleusQuery }>;

      promise
        .then(res => res.data.CheckPersonActiveNucleus)
        .then(data => {
          setShowLoadingSpinner(false);
          if(data?.statusCode === 200 && data.body) {
            setUserStatus(data.body.is_active === "True" ? "Active" : "Inactive");
          } else {
            throw new Error('Something went wrong - 41');
          }
        }).catch(err => {
          setShowLoadingSpinner(false);
          if (err?.message) {
            console.error(err.message, err); 
          } else {
            handleGraphQLErrors(err);
          }
          window.location.href = "error";
        });
    }

    if (signedIn && personType === "Person") {
      if (window.location.pathname !== "/error") {
        checkIfPersonIsActive();
      } else {
        setUserStatus('Active');
      }
    } else if (personType === "Employee") {
      setUserStatus('Active');
    }
  }, [signedIn, personType]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'oAuthSignOut':
          console.log('signedOut');
          // expire cookies
          let expDate = new Date();
          expDate.setHours(expDate.getHours() - 24);
          document.cookie = `selfservice_cognitodomain=;expires=${expDate};domain=.stateauto.com`;
          document.cookie = `selfservice_clientid=;expires=${expDate};domain=.stateauto.com`;
          document.cookie = `selfservice_refreshtoken=;expires=${expDate};domain=.stateauto.com`;
          if (localStorage.getItem('loggingout') && localStorage.getItem('loggingout') === 'true') {
            localStorage.removeItem('loggingout');
            localStorage.setItem('loggedout', 'true');
          }
          break;
        case 'signIn_failure':
          const { message } = payload.data;
          if (message === 'invalid_grant') {
            setIsInvalidUser(true);
          } else if (message === 'invalid_request') {
            setSignInErrorOccurred(true);
          }
          break;
        default:
          // No action
      }
    });
  }, []);

  useEffect(() => {
    if (!document.getElementById("modal")) {
      const modalRoot: HTMLDivElement = document.createElement("div");
      modalRoot.setAttribute("id", "modal");
      document.body.append(modalRoot);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      isAgencyOpsInternalUser(userId).then((data) => {
        if (typeof data === 'boolean') {
          localStorage.setItem('isAgencyOpsInternalUser', encrypt(data.toString()));
          setIsAgencyOpsUser(data);
        } 
      });
    }
  }, [userId])

  let authenticationStatus = null;
  if (signInErrorOccurred) {
    authenticationStatus = (
      <h3>
        <p>An unexpected error occurred.</p>
      </h3>
    );
  } else if (isInvalidUser) {
    authenticationStatus = (
      <h3>
        <p>Invalid user.</p>
      </h3>
    );
  } else if (!signedIn) {
    authenticationStatus = <></>;
  }

  return (
    <>
      {authenticationStatus !== null ? (
        authenticationStatus
      ) : (
        <Router>
          <ThemeProvider theme={fontOverride}>
            {/* <GlobalStyle /> */}
            <SelfServiceGlobalStyle />
            <AppContextProvider>
              <Container>
                <Header
                  isAgencyOpsInternalUser={isAgencyOpsUser}
                  portal={{ isDisable: disablePortal?.isDisable ?? false, message: disablePortal?.message ?? "" }}
                />
                <ScrollToTop />
                <Switch>
                  {disablePortal !== null && !disablePortal.isDisable && userStatus === "Active" && (
                    <>
                      <Route exact path="/logout" component={withRouter(Logout)} />
                      <Route exact path="/contact-listing" component={withRouter(ContactListingReport)} />
                      <Route exact path="/agency-contacts" component={withRouter(AgencyContacts)} />
                      <Route exact path="/terminate-agency-contacts" component={withRouter(TerminateAgencyContacts)} />
                      <Route exact path="/contact-types" component={withRouter(ContactTypes)} />
                      <Route exact path="/general-details" component={withRouter(GeneralDetails)} />
                      <Route exact path="/agency-codes" component={withRouter(AgencyCodes)} />
                      <Route exact path="/primary-agency-code" component={withRouter(PrimaryAgencyCode)} />
                      <Route exact path="/user-privileges" component={withRouter(UserPrivileges)} />
                      <Route exact path="/agency-contact-profile" component={withRouter(AgencyContactDetails)} />
                      <Route exact path="/search-agency-contact" component={withRouter(SearchAgencyContact)} />
                      <Route exact path="/agency-administration" component={withRouter(AgencyAdministration)} />
                      <Route exact path="/communication-preferences" render={() => window.location.href = env.saconnect} />
                      <Route exact path="/error" component={withRouter(ErrorPage)} />
                      <Route exact path="/" component={withRouter(Login)} />
                      {isUnknownRoutes() && (
                            <Route path="*" component={withRouter(ErrorPage)} />
                      )}
                    </>
                  )}
                </Switch>
                {userStatus === "Inactive" && (
                  <ModalAlert
                    title="Profile Inactive"
                    text="Your profile status is inactive. No changes can be made."
                    onClickClose={onClickClose}
                  />
                )}
                {showLoadingSpinner && (
                  <SpinnerContainer>
                    <SASpinner variant="circular-continuous" />
                  </SpinnerContainer>
                )}
              </Container>
            </AppContextProvider>
          </ThemeProvider>
        </Router>
      )}
    </>
  );
}

export default App;
